
import { defineComponent, reactive } from "vue";
import { saveToken } from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import { ElementAnimateUtil } from "@/assets/ts/_utils/ElementAnimateUtil";

export default defineComponent({
  name: "laudo",
  setup() {
    const route = useRouter();
    const laudoId = route.currentRoute.value.params.laudoId;
    const model = reactive({
      laudo: {
        marca: null,
        modelo: null,
        quilometragem: null,
        anoFabricacao: null,
        anoModelo: null,
        chassi: null,
        placa: null,
        items: [],
        evidencias: [],
        documentos: []
      }
    });

    if (!window.localStorage.getItem(`search-${laudoId}`) && !window.localStorage.getItem("USER")) {
      route.push({ name: "check-laudo" });
    }

    const isImage = (fileUrl) => {
      const imgExtensions = ["jpg", "png", "jpeg", "bmp"];
      const videoExtensions = ["mkv", "mp4", "webm"];
      const lastDot = fileUrl.lastIndexOf(".");

      const ext = fileUrl.substring(lastDot + 1);

      if (imgExtensions.includes(ext)) {
        return true;
      } else {
        return false;
      }
    }

    const removeWhiteSpace = (text) => {
      return text?.replace(" ", "");
    }

    const goToMensagemProblema = (item) => {
      ElementAnimateUtil.scrollTo(
        document.getElementById(removeWhiteSpace(item)),
        24
      );
    }

    const getItemsOk = list => {
      if (!list) return;
      return list.filter(x => {
        return x.positivo === true;
      }).length;
    };

    const getCategoriaComProblema = list => {
      if (!list) return;
      return list.filter(x => {
        return x.items.some(y => y.positivo === false);
      })
    };

    const getItemsComProblema = list => {
      if (!list) return;
      return list.filter(x => {
        return x.positivo === false;
      });
    };

    const compare = ( a, b ) => {
      if ( a["ordem"] < b["ordem"] ){
        return -1;
      }
      if ( a["ordem"] > b["ordem"] ){
        return 1;
      }
      return 0;
    }

    const getImagensCategoria = categoria => {
      return model.laudo.evidencias.filter(x => {
        return x["categoria"] == categoria;
        })
        .map(x => {
          return x;
        })
        .sort(compare);
    };

    const getTextoCorreto = categoria => {
      let palavra = categoria;
      switch (categoria) {
        case "ConservacaoInterna":
          palavra = "Conservação Interna";
          break;
        case "ConservacaoExterna":
          palavra = "Conservação Externa";
          break;
        case "Identificacao":
          palavra = "Identificação";
          break;
        case "Perifericos":
          palavra = "Periféricos";
          break;
        default:
          break;
      }
      return palavra;
    }

    ApiService.get(`laudo/${laudoId}`).then(({ data }) => {
      model.laudo = data;
    });


    return {
      model,
      isImage,
      getItemsOk,
      getCategoriaComProblema,
      getItemsComProblema,
      getImagensCategoria,
      goToMensagemProblema,
      removeWhiteSpace,
      getTextoCorreto
    };
  }
});
